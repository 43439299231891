html,body{
    display: block;
    height: 100%;
}

body{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body > .container-fluid{
    flex-grow: 1;
}

a:visited{
    color: red;
}

#body-formulaire{
    overflow: unset!important;
}
select.form-control[multiple] {
    height: 38px;
}
.btnTaille{
    //height: 38px;
    //width: 42px;
    height: 40px;
    width: 40px;

    }
// Login
#login-container {
    margin-top: 15px;
}

.main_logo{
    max-width: 250px;
    width: 100%;
}
.second_logo{
    max-width: 150px;
    width: 100%;
}

// Btns
.biblio.btn{
    outline: none;
    box-shadow: none;
    &:focus{
        outline: none;
        box-shadow: none;
    }
}
.biblio.active{
    border-radius: 50%;
    color: white;
    background-color: #4CA64C;
    &:hover{
        background-color: white;
        color: black;
    }
}

.dropdown-item.logout{
    margin-left: 5px;
    border-radius: 50%;
    padding: 7px 12px;
    height: 38px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{
        background-color: #ff4c4c;
        color: white;
    }
}

// Nav
.nav-tabs a {
    color: #000000
}

.nav-tabs .nav-link {
    border: 1px solid #495057;
    background: #C9C8C9;
}

.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #495057 #495057 #fff;
    font-weight: bold;
}

.row {
    align-items: center;
}

.bg-row-child {
    background-color: rgba(2, 117, 216, 0.15)
}

//DataTable processing message
div.dataTables_wrapper div.dataTables_processing {
    width: 200px;
    height: 125px;
}


//Loader recherche/export
#loader {
    position:fixed; 
    top:0; 
    left:0; 
    height:100%; 
    width:100%;
    display:block;
    background-color:rgba(160, 160, 160, 0.38);

    .center{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .textLoader{
        margin-bottom: 5px;
    }
    
    #progress{
        background-color: darkgrey;
        height: 20px;
        display: none;
    }
    
    .annulerloader{
        display: none;
    }
}

.modal-body > label.col-form-label.col-sm-2{
    font-weight: bold;
    padding-left: 0;
    padding-right: 0;
}
#btn-interface-admin{
    margin-left: 5px;
    border-radius: 50%;
    padding: 7px 12px;
    height: 38px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{
        background-color: #0275d8;
        color: white;
    }
}
#btn-interface-admin-home{
    margin-left: 5px;
    border-radius: 50%;
    padding: 7px 12px;
    height: 38px;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:hover{
        background-color: #4CA64C;
        color: white;
    }
}

.noLabel{
    display: none;
}
.noLabel + .col-sm-10{
    flex: 0 0 100%!important;
    max-width: 100%!important;
    padding: 0px;
}
.highlight.biography{
    background-color: #007bff;
    color: white;
}

.person-name-link{
    font-weight: bold;
}