@media (max-width: 1200px) {
    .form-group.row,
    .form-group > .row {
        flex-direction: column;
        align-items: flex-start;
        > *{
            min-width: 100%;
        }
        > .col-sm-10{
            align-items: center;
        }
    }

    .main-title{
        font-size: 2rem;
    }
}