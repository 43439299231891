// Make sure the bootstrap-sass and lato fonts are resolved correctly
/* Supprimé pour installer Bootstrap 4 */
/* Bootstrap sass Suppr */
// $icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
//$lato-font-path: '~lato-font/fonts';

// @import "~bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "~select2/dist/css/select2.min.css";
@import "~select2-theme-bootstrap4/dist/select2-bootstrap.min.css";

@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~@fortawesome/fontawesome-free/css/v4-shims.css";

@import '../../node_modules/datatables.net-bs4/css/dataTables.bootstrap4.min.css';
@import '../../node_modules/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css';

@import "./global/commun.scss";
@import "./global/biblio.scss";
@import "./global/media.scss";
@import "./security/gestion.scss";