#bibliotheque-part{
    box-shadow: -1px 0px 3px -1px;
    border-radius: 5px;
    overflow-y: auto;

    .section-content{
        > ul{
            padding-inline-start: 0px;
            li > ul{
                padding-inline-start: 10px;
            }
        }
        ul{
            list-style: none;
            ul > li{
                display: flex;
                justify-content: space-between;
                .btn{
                    padding: 0;
                }
                i{
                    cursor: pointer;
                    &:hover{
                        color: grey;
                    }
                }
            }
        }
    }
}