@import "./global/_site_variables.scss";

.alert {
    /* width: 200px; */
    /* background-color: black; */
    /* background-color: rgba(30, 30, 30, 0.9); */
    //text-shadow: 1px 1px black;
    /* color: #eee; */
    padding-left: 65px;
    box-shadow: 4px 3px 15px rgba(0,0,0,0.9);
    border: 0;
    background-repeat: no-repeat;
    background-position: 15px 50%;
    display: none;
    z-index: 1000;
    color: $text_black !important;

    .close {
        /* color: white; */
        /* color: rgba(255, 255, 255, 0.8); */
        text-shadow: 0 1px 0 #000;
        opacity: 1;
    }
}
